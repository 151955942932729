export const FORGOT_SCREEN_LABEL_KEYS = {
    title: 'title',
    subtitle: 'subtitle',
    mobileFieldTitle: 'mobileFieldTitle',
    mobileFieldPlaceholder: 'mobileFieldPlaceholder',
    sumbitButton: 'sumbitButton',
    register: 'register',
}

export const OTP_SCREEN_LABEL_KEYS = {
    title: 'title',
    subtitle: 'subtitle',
    expireMessage: 'expireMessage',
    sumbitButton: 'sumbitButton',
}

export const PASSWORD_SCREEN_LABEL_KEYS = {
    title: 'title',
    subtitle: 'subtitle',
    passcodeFieldTitle: 'passcodeFieldTitle',
    passcodeFieldPlaceholder: 'passcodeFieldPlaceholder',
    confrimPasscodeFieldTitle: 'confrimPasscodeFieldTitle',
    confrimPasscodeFieldPlaceholder: 'confrimPasscodeFieldPlaceholder',
    sumbitButton: 'sumbitButton',
}
