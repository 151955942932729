export const SCREEN_LABEL_KEYS = {
    title: 'title',
    subtitle: 'subtitle',
    emailFieldTitle: 'emailFieldTitle',
    emailFieldPlaceholder: 'emailFieldPlaceholder',
    passcodeFieldTitle: 'passcodeFieldTitle',
    passcodeFieldPlaceholder: 'passcodeFieldPlaceholder',
    rememberMe: 'rememberMe',
    forgotPassword: 'forgotPassword',
    loginButton: 'loginButton',
    or: 'or',
    google: 'google',
    facebook: 'facebook',
    apple: 'apple',
    microsoft: 'microsoft',
    discord: 'discord',
    legalNote: 'legalNote',
    register: 'register',
    registerAccount: 'registerAccount',
}
