import styled from 'styled-components'

const ErrorPage404Style = styled.section`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .btn {
        font-size: 1.2rem;
        margin-top: 3rem;
    }
`

export default ErrorPage404Style

export const ErrorPageStyle = styled.div`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
