import { Col, Row } from 'react-bootstrap'
import { LanguageStyling } from './style'
import UK_Flag from '../../assets/icons/ic_uk_flag.svg'

const Language = (): JSX.Element => {
    return (
        <LanguageStyling>
            <div className="language">
                <h3 className="language-title">Select Your Activity </h3>
                <p className="language-subtitle">Suggestion for you</p>
                <Row>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Boxing</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">
                                Brazilian Jiu Jitsu
                            </p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Breathing</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Capoeira</p>
                        </div>
                    </Col>
                </Row>

                <h4 className="language-subtitle mt-20">All Activities</h4>
                <Row>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Boxing</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">
                                Brazilian Jiu Jitsu
                            </p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Breathing</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Capoeira</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Cross fit</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Eskrima</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Fitness</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">
                                Functional Training
                            </p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">
                                Ginastica Natural
                            </p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Grappling</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Hapkido</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Jeet Kune Do</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Jiu Jitsu</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Ju Jitsu</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Judo</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">K1</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Karate</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Kendo</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Kenpo</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Kick Boxing</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Krav Maga</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Kung Fu</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Luta Livre</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">MMA</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Muay Thai</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Nogi</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Pilates</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Sambo</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Savata</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Self Defense</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Sumo</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Taekwondo</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Tai Chi</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Wing Chun</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Wing Chun</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Wrestling</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Wushu</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="d-flex align-items-center column-gap-3 mb-20">
                            <img
                                src={UK_Flag as string}
                                alt="UK"
                                width={26}
                                height={16}
                            />
                            <p className="language-text m-0">Yoga</p>
                        </div>
                    </Col>
                </Row>
            </div>
        </LanguageStyling>
    )
}
export default Language
